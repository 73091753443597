// Recorder.js
import { CircularProgress, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import { MAX_RECORDING_MINUTES } from "../../utils/constant";
import { useSnackbar } from "notistack";
let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";

const mimeType = MediaRecorder.isTypeSupported("audio/webm") ? "webm" : "mp4";

export default function VoiceRecorder(props) {
  const { handleSubmit, isSubmitting, setIsSubmitting, setIsStartRecording } =
    props;
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [timer, setTimer] = useState(0);
  const [myStream, setMyStream] = useState(null);
  const [recordedData, setRecordedData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const startRecording = async () => {
    try {
      const mic = await navigator.permissions.query({ name: "microphone" });
      if (mic.state === "denied") {
        enqueueSnackbar(micError, { variant: "error" });
        setIsStartRecording(false);
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setMyStream(stream);
      setIsRecording(true);
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: `audio/${mimeType}`,
      });

      mediaRecorder.ondataavailable = async (event) => {
        setRecordedData((old) => [...old, event.data]);
      };

      mediaRecorder.start(100);
      stream.getTracks().forEach((track) => {
        track.onended = () => mediaRecorder.stop();
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setIsStartRecording(false);
      return;
    }
  };

  const handleCancelRecording = () => {
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
    }
    setIsStartRecording(false);
  };

  const stopRecording = () => {
    setIsSubmitting(true);
    const mergedBlob = new Blob(recordedData, {
      type: mimeType,
    });
    handleSubmit({ file: mergedBlob, mimeType, duration: timer });
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleCompleted = () => {
    setIsPaused(true);
    setIsRecording(false);
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleStoped = () => {
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
    setIsPaused(true);
    setTimer(0);
    setIsStartRecording(false);
  };

  useEffect(() => {
    startRecording();
    return () => handleStoped();
  }, []);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRecording]);

  useEffect(() => {
    if (timer >= MAX_RECORDING_MINUTES * 60) {
      handleCompleted();
    }
  }, [timer]);

  let minutes = Math.floor(timer / 60);
  let seconds = timer % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  let recorded_with = (timer / (MAX_RECORDING_MINUTES * 60)) * 100;

  return (
    <div className="d-flex w-100">
      <>
        <div className="recording-slider">
          <div className="stop-recording">
            <Tooltip title="Cancel">
              <ClearIcon onClick={handleCancelRecording} />
            </Tooltip>
          </div>
          <div className="inner-slider">
            <div
              className="recorded-audio"
              style={{
                width: `${recorded_with}%`,
              }}
            ></div>
          </div>
          <div className="recorded-time">{`${minutes}:${seconds}`}</div>
        </div>

        {isSubmitting ? (
          <div className="message-send-icon ms-2 mb-2">
            <CircularProgress
              style={{
                width: 23,
                height: 23,
              }}
            />
          </div>
        ) : (
          <Tooltip title="Send">
            <div className="message-send-icon ms-1" onClick={stopRecording}>
              <SendIcon className="ms-1" />
            </div>
          </Tooltip>
        )}
      </>
    </div>
  );
}
