import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { sort_array_based_on_createdAt } from "../../utils/constant";
import { useEffect } from "react";
import { unread_conversations_list_api } from "../../DAL/Chatting/Chatting";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Conversations from "../Chatting/Conversations";
import { useNavigate } from "react-router-dom";

export default function ChatPopover() {
  const { unreadMessageCount } = useContentSetting();
  const [anchorEl, setAnchorEl] = useState(null);
  const [conversationsList, setConversationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const get_conversations_list = async () => {
    const result = await unread_conversations_list_api();
    if (result.code === 200) {
      setConversationsList(result.conversations_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSelected = (row) => {
    navigate(`/class-room/${row._id}`);
    handleClose();
  };

  useEffect(() => {
    if (!open) return;
    get_conversations_list();
  }, [open]);

  sort_array_based_on_createdAt(conversationsList);

  const conversationsData = {
    conversationsList,
    handleSelected: handleSelected,
    selectedConversation: null,
    is_popup: true,
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div className="header-button-action" onClick={handleClick}>
          <span>{unreadMessageCount}</span>
          <i className="fa-solid fa-comment-dots"></i>
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        className="chat_popover_menu"
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="chat-card-section">
          <div className="p-3 pt-1 chat-card-heading">
            <h6>Messages</h6>
            <span>You have {unreadMessageCount} unread messages</span>
          </div>
          <Divider />
          <div className="chatting-users-list">
            <Conversations {...conversationsData} />
          </div>
        </div>
      </Menu>
    </>
  );
}
